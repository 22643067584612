/* This example requires Tailwind CSS v2.0+ */
import { Disclosure } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
// import { StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import React from 'react'
import { useState } from 'react';


//import { useTranslation } from 'react-i18next';





export default function Example() {
  
  //const { t, i18n } = useTranslation("menus");

  //const [lan, setLan] = useState(i18n.language == "en"? "Español": "English");


  const navigationLeft = [
    { name: "INICIO", href: '/', current: true },
    { name: "SOBRE NOSOTROS", href: '/sobre_nosotros/', current: false },
    { name: "CATALOGOS", href: '/catalogo/', current: false },
  ]
  const navigationRight = [
    { name: "SERVICIOS", href: '/servicios/', current: true },
    { name: "NUESTRAS MARCAS", href: '/nuestras_marcas/', current: true },
    { name: "CONTACTANOS", href: '/contactanos', current: false },
  ]
  
  const navigation = [
    ...navigationLeft,
    ...navigationRight,
  ]

  // function change(){
  //   var language = i18n.language;
  //   if(language == "en"){
  //     i18n.changeLanguage("es");
  //     setLan("English");
  //   }
  //   else{
  //     i18n.changeLanguage("en");
  //     setLan("Español");
  //   }
  //   window.location.reload();
  // }

  function change(){
    window.location.href = "/en/"
  }

  return (
    <Disclosure as="nav" className="bg-primary sticky top-0 z-50">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
            <div className="relative flex items-center justify-between h-16">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-white hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex-shrink-0 flex items-center sm:hidden">
                  {/* <StaticImage className='w-auto' src='../../images/logo.png' alt='Logo HRCigars' width={55}/> */}
                </div>
                <div className="hidden sm:block sm:ml-6 w-full mr-6">
                  <div className='flex w-full justify-items-center '>
                    <div className="flex grow h-14 justify-center items-center flex-row gap-4">
                      {navigation.map((item, index) =>{
                        return(
                          <div className='' key={index}>
                            <Link activeClassName='bg-primary-dark text-secondary-light font-bold' className='font-bold text-white px-3 pt-1 pb-1 block rounded hover:bg-primary-dark' to={item.href}>{item.name}</Link>
                          </div>
                        )
                      } )}
                      <div>
                        <button activeClassName='bg-primary-dark text-secondary-light font-bold' className='text-white px-3 pt-1 pb-2 block rounded hover:bg-primary-dark' onClick={change}>English</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1">
              {navigation.map((item, index) => (
                <div className='' key={index}>
                  <Link activeClassName='bg-primary-dark text-secondary-light font-bold' className='text-white px-3 pt-1 pb-2 block rounded hover:bg-primary-dark' to={item.href}>{item.name}</Link>
                </div>
              ))}
              <div>
                <div activeClassName='bg-primary-dark text-secondary-light font-bold' className='text-white px-3 pt-1 pb-2 block rounded hover:bg-primary-dark' onClick={change}>English</div>
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}